.button-wrap {
  filter: drop-shadow(0px 2.5px 0px $rgbacolor_beige_ombre);
  transition: 0.2s;

  &:hover {
    filter: drop-shadow(0px 0px 0px $rgbacolor_beige_ombre);
  }

  &:active {
    filter: drop-shadow(0px -2.5px 0px $rgbacolor_beige_ombre);
  }
}

button {
  &:focus {
    box-shadow: none !important;
  }
}

button.btn {
  @include bevel(5px);

  line-height: 32px !important;
  border: none;
  outline: none !important;
  box-shadow: none;

  @include padding-vertical(0.25rem);

  border-radius: 0px;

  &-nav {
    font-family: Ander Hedge;
  }

  &-middle {
    @include bevel(0px);

    border-right: 2px solid white !important;
  }

  &-half-left {
    border-radius: 0px;

    border-right: 2px solid white !important;

    @include half-bevel-left(5px);
  }

  &-half-right {
    border-radius: 0px;

    @include half-bevel-right(5px);
  }

  &:hover {
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &-primary {
    background-color: $color_button;
    color: white;

    &:focus {
      background-color: $color_button;
      box-shadow: none !important;
    }

    &:disabled,
    &:hover,
    &:active,
    &:target {
      background-color: $color_button_hover !important;
    }

    &-alt {
      background-color: white;
      color: $color_button;

      &:focus {
        background-color: white;
      }

      &:disabled,
      &:hover {
        background-color: $color_background;
        color: $color_button !important;
      }
    }
  }

  &-secondary {
    background-color: $hexcolor_turquoise;
    color: white;

    &:focus {
      background-color: $color_button;
      box-shadow: none !important;
    }

    &:disabled,
    &:hover,
    &:active,
    &:target {
      background-color: $hexcolor_turquoise_light !important;
    }

    &-alt {
      background-color: white;
      color: $color_button;

      &:focus {
        background-color: white;
      }

      &:disabled,
      &:hover {
        background-color: $color_background;
        color: $color_button !important;
      }
    }
  }

  &-mini {
    line-height: 1rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    @include padding-vertical(0.125rem);
  }

  &-fb {
    background-color: $color_facebook;
    color: white;

    &:hover {
      background-color: $color_facebook_hover;
    }
  }

  &-apple {
    background-color: black;
    color: white;

    &:hover {
      background-color: #202020;
    }
  }
}

.button-link {
  &:hover {
    text-decoration: none;
  }
}

.dropdown-alt .dropdown-menu {
  background-color: white;
  color: $color_button;
}

.dropdown-alt .dropdown-item {
  color: $color_button;
}

.navbar-toggler {
  background-color: white !important;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28239, 64, 68, 1' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  }
}

.bevel-border-button-inner {
  @include bevel(3px);
  margin: 2px;
  background-color: white;
}

.currency {
  z-index: 1000;
}
