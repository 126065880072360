.price-card {
  &__wrapper {
    height: 100%;
    padding: 5px;
    background-color: $hexcolor_red_bright;
    border-radius: 5px;

    display: flex;
    flex-direction: column;

    &--month {
      background: none;
    }
  }

  flex-grow: 1;

  &__highlight {
    color: white;
    display: flex;
    justify-content: center;

    &--month {
      visibility: hidden;
    }
  }

  &__price {
    color: $hexcolor_red;

    .price__amount {
      font-weight: bold;
      font-size: 3rem;
    }

    &--coupon {
      background-color: $rgbacolor_red_x_light;
      position: relative;

      border-radius: 10px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;

      padding-left: 5rem;
      padding-right: 1rem;
      padding-bottom: 0.5rem;

      .price-card__coupon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4rem;
        color: white;
        background-color: $hexcolor_red_bright;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;

        i {
          font-size: 1.5rem;
        }

        span {
          width: 4rem;
          padding: 0.25rem;
          font-size: 0.6rem;
        }
      }
    }
  }

  .marketing-title {
    text-transform: uppercase;
    font-weight: bold;
  }

  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 3rem;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(98, 157, 155, 0.35);

  h2,
  .marketing-title {
    color: $hexcolor_turquoise;
  }

  &-month {
    .deal-tag {
      display: none;
    }

    .price-card__button {
      background-color: $hexcolor_turquoise !important;
    }
  }

  &-year {
    .deal-tag {
      width: 6rem;
      height: 6rem;
      background: url("../../../../assets/Backgrounds/green_tag_background.svg");
      background-size: 100% 100%;
      position: absolute;
      top: 2rem;
      right: -1.25rem;
      transform: rotate(349deg);
      color: white;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
      }

      .value {
        font-weight: bold;
        font-size: 1.25rem;
      }
    }

    .price-card__button {
      background-color: $hexcolor_green_bright !important;
    }
  }

  &-footer {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;

    align-items: center;

    &-checkmark {
      display: flex;
      justify-content: center;
      align-items: center;

      &-img {
        img {
          width: 2em;
          height: 1.5em;
          margin-right: 1em;
        }
      }
    }
  }

  &__button {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem !important;
  }

  .referral-coupon-display {
    &__amount {
      text-decoration: line-through;

      &-with-discount {
        font-weight: bold;
      }
    }

    &__coupon {
      margin-left: 1em;
      padding: 0.5em 1em;

      background-color: white;
      color: $color_button;
      font-weight: bold;

      display: flex;
      align-items: center;

      i {
        margin-right: 0.5em;
        font-size: 1.25em;
      }
    }
  }
}
