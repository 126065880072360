.day-display {
  .day-box {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
    font-weight: bold;
    background-color: $hexcolor-red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2px;

    &.weekend {
      background-color: $rgbacolor-weekend;
    }

    &.inactive {
      filter: opacity(30%);
    }

    &:hover {
      cursor: pointer;
    }
  }
}
