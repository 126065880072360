.subscription-banner {
  background-color: $hexcolor_turquoise_medium;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &__image {
    width: 100%;
  }

  &__text {
    font-size: 0.8rem;
    color: white;
  }

  .button-wrap {
    width: 100%;
  }

  &__button {
    color: $hexcolor_turquoise !important;
    white-space: nowrap;
    min-width: 100%;
    font-family: "Ander Hedge";
  }
}
