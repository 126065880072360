.download {
  &__divider {
    display: flex;
    justify-content: center;
    overflow-x: hidden;

    h4 {
      color: white;
      display: inline-block;
      position: relative;
    }

    h4::before,
    h4::after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 2px;
      background: white;
    }

    h4::before {
      right: 100%;
      margin-right: 15px;
    }

    h4::after {
      left: 100%;
      margin-left: 15px;
    }
  }
}
