a {
  color: $hexcolor_red;
  text-decoration: underline;

  &:hover {
    color: $hexcolor_red;
    text-decoration: none;
  }

  &.in-line {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.nav-link {
  text-decoration: none;
}
