.nav-pills {
  .nav-item {
    filter: drop-shadow(0px 2.5px 0px $rgbacolor_beige_ombre);
  }

  .nav-link {
    border-radius: 0;
    text-decoration: none;
    color: $hexcolor_red;
    background-color: white;
    font-weight: bold;

    margin-bottom: calc(0.5rem + 10px);

    &.active {
      color: white;
      background-color: $hexcolor_red !important;
      margin-bottom: 0px;
      padding-bottom: calc(0.5rem + 10px);
      @include tab-bottom(10px);
    }
  }
}
