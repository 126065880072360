.dropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }

    .icon-dropdown:before {
      content: "\e900";
      display: inline-block;
      transform: rotate(-90deg);
      margin-left: -0.5px;
      transition-duration: 0.5s;
    }
  }

  .dropdown-menu {
    box-shadow: none;
    border: none;
    filter: drop-shadow(0px 0px 2.5px $rgbacolor_beige_ombre);
    background: none;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .dropdown-menu-inner {
    padding-top: calc(0.5rem + 5px);
    padding-bottom: 0.5rem;

    @include bevel-with-top-tab(5px);
    background-color: white;

    .dropdown-item {
      font-weight: bold;
      color: $hexcolor_turquoise;
    }
  }

  &.show {
    .dropdown-toggle {
      background-color: $hexcolor_red;

      .icon-dropdown:before {
        content: "\e900";
        display: inline-block;
        transform: rotate(90deg);
        transition-duration: 0.5s;
      }
    }
  }
}
