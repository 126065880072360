div.card {
  @include bevel(5px);

  background-color: white;
  border: none;

  &.bg {
    &-beige {
      background-color: $color_background;
    }

    &-red {
      background-color: $hexcolor_red;
    }
  }

  &-wrap {
    filter: drop-shadow(0px 4px 0px $rgbacolor_beige_ombre);

    &.selected {
      filter: drop-shadow(0px 4px 0px $rgbacolor_green);

      .card::after {
        content: "";

        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;

        width: 80px;
        height: 80px;
        top: -40px;
        left: -40px;
        background-color: $hexcolor_green;
        transform: rotate(45deg);
      }

      .card::before {
        font-family: "icomoon" !important;
        content: "\e904";
        position: absolute;
        z-index: 10;
        top: 0px;
        left: 0px;
        padding-left: 5px;
        font-size: 24px;
      }
    }
  }

  &-logo {
    @include bevel-with-top(5px);
  }
}

.logo-card {
  margin-top: 75px;
}
