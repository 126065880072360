/*
 * Hide only visually, but have it available for screenreaders.
 */

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 25px;
  padding-bottom: 17px;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
    display: block;
    width: 30px;
    height: 30px;
  }
}

input[type="checkbox"] + label {
  color: $hexcolor-beige-ombre;

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "icomoon" !important;
    content: "\e909";
  }

  &:hover {
    cursor: pointer;
  }
}

input[type="checkbox"]:focus + label {
  text-decoration: underline;
}

input[type="checkbox"]:checked + label {
  color: $hexcolor-green;
  font-size: 2rem;

  &:before {
    font-family: "icomoon" !important;
    content: "\e904";
  }

  &:hover {
    color: $hexcolor-green-medium;
  }
}
