@mixin bevel($size) {
  clip-path: polygon(
    // Top left corner points.
    0% #{$size},
    #{$size} 0%,
    // Top right corner points.
    calc(100% - #{$size}) 0%,
    100% #{$size},
    // Bottom right corner points.
    100% calc(100% - #{$size}),
    calc(100% - #{$size}) 100%,
    calc(100% - #{$size}) calc(100% * 10),
    // Bottom left corner points.
    #{$size} calc(100% * 10),
    #{$size} 100%,
    0% calc(100% - #{$size})
  );
}

@mixin bevel-with-top-tab($size) {
  clip-path: polygon(
    // Top left corner points.
    0% calc(#{$size} + #{$size}),
    #{$size} #{$size},
    calc(50% - #{$size}) #{$size},
    50% 0%,
    calc(50% + #{$size}) #{$size},
    // Top right corner points.
    calc(100% - #{$size}) #{$size},
    100% calc(#{$size} + #{$size}),
    // Bottom right corner points.
    100% calc(100% - #{$size}),
    calc(100% - #{$size}) 100%,
    calc(100% - #{$size}) 100%,
    calc(100% - #{$size}) calc(100% * 2),
    // Bottom left corner points.
    #{$size} calc(100% * 2),
    #{$size} 100%,
    0% calc(100% - #{$size})
  );
}

@mixin bevel-with-top($size) {
  clip-path: polygon(
    // Top left corner points.
    0% #{$size},
    #{$size} 0%,
    #{$size} -75px,
    calc(100% - #{$size}) -75px,
    // Top right corner points.
    calc(100% - #{$size}) 0%,
    100% #{$size},
    // Bottom right corner points.
    100% calc(100% - #{$size}),
    calc(100% - #{$size}) 100%,
    calc(100% - #{$size}) 100%,
    calc(100% - #{$size}) calc(100% * 2),
    // Bottom left corner points.
    #{$size} calc(100% * 2),
    #{$size} 100%,
    0% calc(100% - #{$size})
  );
}

@mixin half-bevel-left($size) {
  clip-path: polygon(
    // Top left corner points.
    0% #{$size},
    #{$size} 0%,
    // Top right corner.
    100% 0%,
    // Bottom right corner.
    100% 100%,
    // Bottom left corner points.
    #{$size} 100%,
    0% calc(100% - #{$size})
  );
}

@mixin half-bevel-right($size) {
  clip-path: polygon(
    // Top left corner.
    0% 0%,
    // Top right corner points.
    calc(100% - #{$size}) 0%,
    100% #{$size},
    // Bottom right corner points.
    100% calc(100% - #{$size}),
    calc(100% - #{$size}) 100%,
    // Bottom left corner.
    0% 100%
  );
}

@mixin bottom-slant-up($size) {
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - #{$size}), 0% 100%);
}

@mixin bottom-slant-down($size) {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% calc(100% - #{$size}));
}

@mixin tab-bottom($size) {
  clip-path: polygon(
    // Top.
    0% 0%,
    100% 0%,
    // Bottom.
    100% calc(100% - #{$size}),
    calc(50% + #{$size}) calc(100% - #{$size}),
    50% 100%,
    calc(50% - #{$size}) calc(100% - #{$size}),
    0% calc(100% - #{$size})
  );
}
