@mixin desktop-only {
  @media screen and (min-width: 992px) {
    // 992px matches Bootstrap lg breakpoint
    @content;
  }
}

.desktop-only-image {
  display: none;

  @include desktop-only {
    display: block;
  }
}

.pharmacy-partner {
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  max-width: 1920px; // Add this line to prevent excessive stretching
  margin: 0 auto; // Center the content when screen is wider

  .background-image-top {
    position: absolute;
    top: -5.5rem;
    right: -7rem;
    width: 35%;
    height: auto;
    z-index: 0;

    .main-image {
      position: relative;
      width: 40rem;
      height: auto;
      object-fit: contain;
    }

    .left-floating-icons {
      position: absolute;
      top: 25rem;
      left: -2rem;
      z-index: 2;
      img {
        width: 7rem;
        height: auto;
      }
    }

    .bottom-floating-icons {
      position: absolute;
      top: 30rem;
      left: 10rem;
      img {
        width: 20rem;
        height: auto;
      }
    }
  }

  .background-image-middle {
    position: absolute;
    width: 16rem;
    left: -20rem;
    height: auto;
    z-index: 0;

    .left-polygon {
      position: relative;
      left: 2rem;
      top: 2rem;
      width: 100%;
      height: auto;
    }

    .left-floating-icons {
      position: absolute;
      top: 3rem;
      left: 9rem;
      width: 12rem;
      height: auto;
      z-index: 2;
    }
  }

  .background-image-bottom {
    position: absolute;
    bottom: 0rem;
    right: 0rem;
    width: 28rem;
    height: auto;
    z-index: 0;

    .bottom-polygon {
      @media (min-width: 992px) {
        width: 100%;
        bottom: -13rem;
        right: -9rem;
      }
      @media (min-width: 1920px) {
        width: 100%;
        bottom: -13rem;
        right: calc((100vw - 1920px) / 2 - 9rem);
      }
      @media (max-width: 991px) {
        width: 70%;
        top: 10rem;
        right: -15rem;
      }
      position: relative;

      height: auto;
    }
  }

  .content-wrapper {
    position: relative;
    z-index: 1;
    max-width: 85rem;
    margin: 0 auto;
    padding: 0rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;

    .logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: clamp(0rem, 0rem + 2vw, 2rem);

      img {
        object-fit: contain;
        &.kairos-logo {
          height: clamp(1.5rem, 1.5rem + 2vw, 3.5rem);
        }
        &.accespharma-logo {
          height: clamp(2rem, 2rem + 2vw, 6rem);
        }
      }
    }

    .language-button {
      margin-right: 0rem;
      padding: 0.25rem 1.5rem;
      border: none;
      border-radius: 9999px;
      background-color: #ffffff;
      color: #31737c;
      font-weight: 700;
      width: clamp(6rem, 7.5rem + 2vw, 10rem);
      transition: all 0.2s;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: #f1f5f9;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .main-content {
    max-width: 45rem;
    margin: 0;
    padding: 1rem;

    .title {
      color: #fff;
      font-family: "Open Sans";
      font-size: clamp(1rem, 1rem + 2vw, 1.75rem);
      font-style: normal;
      font-weight: 700;
      text-transform: none;
      letter-spacing: 0.05rem;
      line-height: clamp(2rem, 2rem + 2vw, 3rem);
      text-align: left;
      margin: 0 1.5rem;
      margin-bottom: 2rem;
    }
    .title span {
      background-color: #31737c;
      padding: 0.05rem 0rem;
      -webkit-box-shadow: 1.5rem 0px 0px #31737c, -1.5rem 0px 0px #31737c;
      box-shadow: 1.5rem 0px 0px #31737c, -1.5rem 0px 0px #31737c;
      -webkit-box-decoration-break: clone;
      -moz-box-decoration-break: clone;
      box-decoration-break: clone;
    }

    .description {
      color: #31737c;
      line-height: normal;
      font-weight: 400;
      margin-bottom: 2rem;
      font-size: 1.125rem;
      text-align: left;
    }

    .highlight {
      color: #31737c;
      font-weight: 700;
      font-size: 1.125rem;
      margin-bottom: 2rem;
      text-align: left;
    }

    .divider {
      width: 100%;
      max-width: 40rem;
      height: 0.2rem;
      margin: 0;
      background-color: #31737c;
    }
  }

  .premium-offer {
    max-width: 60rem;
    margin: 1rem auto;
    padding: 1rem 1rem;
    text-align: center;

    .offer-text {
      color: #31737c;
      text-align: center;
      font-family: "Open Sans";
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .cta-button {
      background-color: #ef4044;
      color: #ffffff;
      padding: 1rem 2rem;
      border-radius: 20px;
      font-weight: 500;
      border: none;
      transition: background-color 0.2s;
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 60%;
      min-width: 20rem;

      &:hover {
        background-color: #dc2626;
      }
    }

    .disclaimer {
      color: #31737c;
      text-align: center;
      font-family: "Open Sans";
      font-size: 0.9375rem;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
  }

  .benefits-section {
    background-color: #e8f4f4;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-top: 2rem;

    .benefits-content {
      width: 100%;
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 1rem;
      position: relative;
      z-index: 2;

      .row {
        display: flex;
        flex-wrap: wrap;
      }

      .hero-image-left {
        @media (min-width: 992px) {
          order: 1;
        }
        @media (max-width: 991px) {
          order: 2;
          margin-top: 2rem;
          left: -1rem;
          flex-basis: calc(55% - 1.5rem);
        }

        justify-content: center;
        position: relative;
        top: -1rem;

        img {
          width: 20rem;
          max-width: 100%;
        }
      }

      .benefits-text {
        @media (min-width: 992px) {
          order: 2;
        }
        @media (max-width: 991px) {
          order: 1;
        }
        margin: 2rem auto 0;
        height: fit-content;

        h2 {
          color: #31737c;
          font-size: 1.5rem;
          margin-bottom: 2rem;
          text-align: left;
          font-family: "Open Sans";
          font-weight: 700;
          letter-spacing: normal;
        }

        .benefits-list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          font-size: 1.125rem;
          padding: 0;

          li {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            color: #31737c;
            text-align: left;

            .bullet {
              width: 0.5rem;
              height: 0.5rem;
              background-color: #31737c;
              border-radius: 9999px;
              flex-shrink: 0;
            }
          }
        }
      }

      .hero-image-right {
        @media (min-width: 992px) {
          order: 3;
        }
        @media (max-width: 991px) {
          order: 3;
          top: -1.5rem;
          right: -5rem;
          margin-top: 2rem;
          flex-basis: calc(48% - 1.5rem);
        }
        justify-content: center;
        position: relative;
        top: -7rem;
        right: -12rem;

        img {
          width: 16rem;
          max-width: 100%;
        }
      }
    }
  }
}
