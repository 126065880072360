.mobile-products {
  &__checkout {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 15px;

    border: none;
    border-radius: 999px !important;
    background-color: $hexcolor_green_bright !important;

    padding: 10px;

    color: white;
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.mobile-product-card {
  border: 3px solid $hexcolor_beige;
  border-radius: 5px;
  overflow: hidden;

  background-color: white;

  .price__amount {
    color: $hexcolor_red_bright;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .price__interval {
    color: $hexcolor_red_bright;
  }

  &--selected {
    border-color: $hexcolor_turquoise;
  }

  &--year,
  &--free {
    .mobile-product-card__popular-tag {
      background-color: $hexcolor_red_bright;
      color: white;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &--month {
    .mobile-product-card__popular-tag {
      display: none;
    }
  }

  &__content {
    padding: 0.5rem;

    input[type="radio"] {
      color: $hexcolor_turquoise;
    }
  }

  &__radio {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1rem;

    border: 1px solid $hexcolor_beige_ombre;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &--checked {
      border: 0.45rem solid $hexcolor_turquoise;
    }
  }
}
