.rc-time-picker-input {
  margin: 0;
  height: 100% !important;
  line-height: 32px !important;
  border: none !important;
  font-size: 1rem !important;
  border-radius: 0px !important;

  &:focus {
    border: none !important;
    transition: none !important;
    outline: none !important;
  }
}

.rc-time-picker-panel-inner {
  margin-top: 45px !important;
}
