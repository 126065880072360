/* The switch - the box around the slider */
.toggle-switch {
  & > * {
    vertical-align: middle;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    filter: drop-shadow(0px 2.5px 0px $rgbacolor_beige_ombre);
  }

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @include bevel(5px);
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    @include bevel(3px);
  }

  input:checked + .slider {
    background-color: $hexcolor_red;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $hexcolor_red;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.task-toggle-switch {
  .task-status-message {
    &-done {
      color: $hexcolor_green_dark;
    }

    &-not-done {
      color: $hexcolor_red;
    }
  }

  .btn-task-validation {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 24px;

    &-false {
      color: $hexcolor_red_light;
      border-color: $hexcolor_red_light;
      background-color: $hexcolor_red_light;

      &:active,
      &:focus,
      &:hover {
        color: $hexcolor_red !important;
      }

      &.selected {
        color: white;
        border-color: $hexcolor_red;
        background-color: $hexcolor_red;

        div {
          background-color: $hexcolor_red;
        }

        &:active,
        &:focus,
        &:hover {
          color: white !important;
        }
      }
    }

    &-na {
      font-size: 16px;
      color: $hexcolor_turquoise_light;
      border-color: $hexcolor_turquoise_medium;
      background-color: $hexcolor_turquoise_medium;

      &:active,
      &:focus,
      &:hover {
        color: $hexcolor_turquoise !important;
        border-color: $hexcolor_turquoise !important;
        background-color: $hexcolor_turquoise !important;
      }

      &.selected {
        color: white;
        border-color: $hexcolor_turquoise;
        background-color: $hexcolor_turquoise;

        div {
          background-color: $hexcolor_turquoise;
        }

        &:active,
        &:focus,
        &:hover {
          color: white !important;
        }
      }
    }

    &-true {
      color: $hexcolor_green_medium;
      border-color: $hexcolor_green_medium;
      background-color: $hexcolor_green_medium;

      &:active,
      &:focus,
      &:hover {
        color: $hexcolor_green_dark !important;
        border-color: $hexcolor_green_dark !important;
        background-color: $hexcolor_green_dark !important;
      }

      &.selected {
        color: white;
        border-color: $hexcolor_green_dark;
        background-color: $hexcolor_green_dark;

        div {
          background-color: $hexcolor_green_dark;
        }

        &:active,
        &:focus,
        &:hover {
          color: white !important;
        }
      }
    }
  }
}
