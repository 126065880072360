.expired-subscription {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  padding-top: 4rem;
  display: flex;
  align-items: center;

  &__message-wrap {
    filter: drop-shadow(0px 4px 0px $rgbacolor_beige_ombre);
  }

  &__message {
    @include bevel(5px);
    background-color: $hexcolor-red-light;
    padding: 1rem;
    text-align: center;
    margin: 1rem;

    font-weight: bold;
    font-family: Ander Hedge;
    font-size: 1.5rem;
  }
}

@supports (
  ((-webkit-backdrop-filter: blurd(0.5em)) or (backdrop-filter: blur(0.5em)))
) {
  .expired-subscription {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(0.5em);
    backdrop-filter: blur(0.5em);
  }
}
