.grid {
  &-settings {
    &-top {
      min-height: 3rem;
    }
  }

  &-dashboard {
    &-top {
      height: 3.5rem;
    }
  }
}
