$mp-unit: 0.35em;
$back-button-bevel-root: 0.35em;
$button-dimension-x: calc(#{$mp-unit} * 10);
$button-dimension-y: calc(#{$mp-unit} * 8.5);
$button-dimension-x-ipad: calc(#{$mp-unit} * 7);
$button-dimension-y-ipad: calc(#{$mp-unit} * 6);

.back-button {
  &__wrapper {
    filter: drop-shadow(0px 4px 4px $rgbacolor_shadow);
  }

  &__element {
    background-color: white;
    border: none;
    width: $button-dimension-x;
    height: $button-dimension-y;
    display: flex;
    justify-content: center;
    align-items: center;

    clip-path: polygon(
      // Top left corner points.
      0% #{$back-button-bevel-root},
      #{$back-button-bevel-root} 0%,
      // Top right corner points.
      calc(100% - #{$back-button-bevel-root}) 0%,
      100% #{$back-button-bevel-root},
      // Bottom right corner points.
      100% calc(100% - calc(2 * #{$back-button-bevel-root})),
      calc(100% - calc(2 * #{$back-button-bevel-root})) 100%,
      // Bottom left corner points.
      0% calc(100% - #{$back-button-bevel-root})
    );

    i {
      font-size: 2rem;

      color: $hexcolor_red;

      filter: drop-shadow(0px 2px 0px $rgbacolor_shadow);
    }
  }
}
