@import "abstracts/_abstracts-dir";

@import "base/typography";
@import "base/base";
@import "base/icons";

@import "components/buttons";
@import "components/forms";
@import "components/loading";
@import "components/stripe";
@import "components/checkout";
@import "components/modal";
@import "components/alerts";
@import "components/switches";
@import "components/tabs";
@import "components/links";
@import "components/routine-card";
@import "components/timeline";
@import "components/day-display";
@import "components/checkbox";
@import "components/timepicker";

@import "layout/subcontainer";
@import "layout/dashboard";
@import "layout/billing";
@import "layout/grid.scss";

@import "../components/Components.scss";
@import "../features/_features-dir";
@import "../pages/_pages-dir";

.firebase-emulator-warning {
  display: none;
}
