.carousel-button-group {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.routine-card {
  &-inner {
    padding: 1.25rem;
    padding-bottom: 2rem;
    background-color: $hexcolor-beige;
    margin-bottom: 60px;

    &-up {
      @include bottom-slant-up(20px);
    }

    &-down {
      @include bottom-slant-down(20px);
    }
  }

  &-title {
    font-size: larger;
    font-weight: bold;

    &.color-0 {
      color: $rgbacolor-routine-0;
    }

    &.color-1 {
      color: $rgbacolor-routine-1;
    }

    &.color-2 {
      color: $rgbacolor-routine-2;
    }

    &.color-3 {
      color: $rgbacolor-routine-3;
    }
  }

  &-time {
    color: $hexcolor-beige-ombre;
  }

  &-body {
    ul {
      padding-inline-start: 20px;
    }
  }

  &-link {
    &:hover {
      text-decoration: none;

      .card-wrap {
        filter: drop-shadow(0 0 0);
      }
    }
  }

  .top {
    height: 10px;
  }

  &.color-0 {
    filter: drop-shadow(0px 2.5px 0px $rgbacolor-routine-0);

    .top {
      background-color: $rgbacolor-routine-0;
    }
  }

  &.color-1 {
    filter: drop-shadow(0px 2.5px 0px $rgbacolor-routine-1);

    .top {
      background-color: $rgbacolor-routine-1;
    }
  }

  &.color-2 {
    filter: drop-shadow(0px 2.5px 0px $rgbacolor-routine-2);

    .top {
      background-color: $rgbacolor-routine-2;
    }
  }

  &.color-3 {
    filter: drop-shadow(0px 2.5px 0px $rgbacolor-routine-3);

    .top {
      background-color: $rgbacolor-routine-3;
    }
  }
}

.routine-edition-card {
  border-left-width: 10px !important;
  border-left-style: solid !important;
  background-color: $hexcolor-beige !important;
  cursor: pointer;
  color: $hexcolor_red;

  &.color-0 {
    border-left-color: $rgbacolor-routine-0 !important;
  }

  &.color-1 {
    border-left-color: $rgbacolor-routine-1 !important;
  }

  &.color-2 {
    border-left-color: $rgbacolor-routine-2 !important;
  }

  &.color-3 {
    border-left-color: $rgbacolor-routine-3 !important;
  }

  .routine-edit-icon {
    font-size: 1.5rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 1rem;
  }
}

.routine-icons {
  font-size: 1.5rem;

  i.enabled {
    color: $hexcolor-green;
  }

  div {
    cursor: pointer;
  }
}

.routine-child-label {
  background-color: white;
  padding: 0.25rem 0.5rem;
  font-weight: bold;
  @include bevel(3px);

  &-wrapper {
    filter: drop-shadow(0px 2.5px 0px $hexcolor_beige_ombre);
  }
}

.child-picker-field {
  cursor: pointer;

  &-wrapper:hover {
    filter: drop-shadow(0px 0px 0px $hexcolor_beige_ombre) !important;
  }
}

.routine-task-icon-picker {
  &:hover {
    background-color: $hexcolor-beige;
    cursor: pointer;
  }
}

.routine-task-edit-alert {
  color: $hexcolor-red;
  cursor: pointer;
  padding: 5px;

  &:hover {
    background-color: $hexcolor-beige;
  }
}

.routine-task-edit-name {
  border: 1px solid $hexcolor-beige-ombre;
  padding: 5px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 5px !important;

  &:hover {
    background-color: $hexcolor-beige;
  }
}
