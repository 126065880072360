@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400&display=swap");

$font-stack: "Open Sans", sans-serif;

@font-face {
  font-family: "Ander Hedge";
  src: url("fonts/Ander\ Hedge.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.text-danger {
  color: $hexcolor-red;
}

.text-colour-white {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Ander Hedge, Arial;
  letter-spacing: 0.1em;
  color: $color_heading;
  font-weight: bold;
  text-align: center;
}

h1 {
  text-transform: uppercase;
}

.h2-like {
  text-transform: capitalize;
  font-size: 2rem;
}
