.free-account {
  border: 5px solid $hexcolor_red_bright;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-bottom: 3rem;

  &__subtitle {
    font-size: 2rem;
    color: $hexcolor_red_bright;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__avatar {
    position: absolute;
    bottom: -1.5rem;
    right: -5rem;
    max-height: 80%;
  }

  &__button {
    background-color: $hexcolor_green_bright !important;
    width: 80%;
    font-weight: bold;
  }

  &__mobile-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 290px;
    }
  }
}
