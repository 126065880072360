%form__input {
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.form__input--text {
  @extend %form__input;
}
