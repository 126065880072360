.form__form-group {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin-right: auto;
  }
}

.form__validation-message {
  color: #d9534f;
  margin-left: auto;
}
