.form__input--password {
  @extend %form__input;
  align-items: stretch;
  display: flex;
  padding: 0;

  input {
    @extend %form__input;
    border: 0;
    z-index: 1;
  }
}

.password-input__append {
  align-self: stretch;

  button {
    align-items: center;
    background-color: $color-background;
    border: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    z-index: 0;
  }
}
