.referral-card {
  text-align: center;

  padding: 2em;

  img {
    max-width: 100%;
  }

  input {
    flex-grow: 1;
  }

  .referral__copy {
    background-color: $hexcolor_red;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0.5em;
  }

  .icon-copy {
    cursor: pointer;

    font-size: 1.5rem;
    margin-right: 0.5em;

    path {
      fill: "#fff";
    }
  }
}
