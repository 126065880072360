.custom-navbar {
  width: 100%;
  top: 0px;
  z-index: 100000;
  padding: 10px 10px 10px 10px;
  color: white;

  &.app {
    background-color: $hexcolor_red_bright;
  }

  .nav-item {
    .nav-link {
      font-family: "Ander Hedge";
      font-size: larger;
      letter-spacing: 2px;
      color: white !important;
      padding-bottom: 0.25rem;

      &.selected {
        border-bottom: 3px solid white;
      }
    }
  }

  .dropdown-item {
    text-decoration: none;
  }

  .header-logo {
    max-height: 50px;

    &-simple {
      max-height: 30px;
    }
  }

  .language-toggler {
    font-family: Ander Hedge, "Arial";
    letter-spacing: 2.5px;
    cursor: pointer;

    &.color-white {
      color: white;
    }

    &:hover {
      color: $hexcolor_beige_ombre;
    }
  }

  .button-wrap {
    filter: none;
  }

  button.btn.header__button {
    background-color: white;
    color: $hexcolor_red;
    font-family: "Ander Hedge";
    width: 10rem;
    padding-left: 0 !important;
    padding-right: 0 !important;

    .header__button-arrow {
      width: 0;
      height: 1.5rem;
      padding: 0.25rem;
      transition: width 0.5s;
    }

    &:hover {
      color: $hexcolor_red_bright !important;

      .header__button-arrow {
        width: 1.5rem;
      }
    }
  }
}
