.subscription-success {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100000;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;

  &__dialog {
    background: url("../../assets/Backgrounds/dialog_background.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
  }

  &__tag {
    max-width: 100%;
    max-height: 100px;
  }

  &__avatars {
    position: fixed;
    max-width: 50vw;

    bottom: -10vh;

    &--left {
      left: -10vw;
    }

    &--right {
      right: -10vw;
    }

    @media (min-width: 576px) {
      bottom: -20vh;
    }

    @media (min-width: 768px) {
      max-width: 50vw;
      bottom: -200px;

      &--left {
        left: -200px;
      }

      &--right {
        right: -200px;
      }
    }
  }
}

@supports (
  ((-webkit-backdrop-filter: blurd(0.5em)) or (backdrop-filter: blur(0.5em)))
) {
  .subscription-success {
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(0.5em);
    backdrop-filter: blur(0.5em);
  }
}
