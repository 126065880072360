.dashboard {
  &-section {
    &-validation {
      height: auto;
      overflow-y: auto;

      margin-bottom: 3rem;

      @media only screen and (min-width: 576px) {
        min-height: 500px;
        max-height: 700px;
      }
    }
  }
}
