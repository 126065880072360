.billing-page {
  .form-control[type="text"] {
    border-radius: 999px;
    border: 1px solid $hexcolor_beige_ombre;
  }

  .form-control[type="text"],
  .form-control[type="text"]::placeholder {
    color: $hexcolor_turquoise;
    font-weight: bold;
  }

  .form-control[type="text"]::placeholder {
    opacity: 0.75;
  }

  &__promo-input--error.form-control[type="text"] {
    border: 1px solid $hexcolor_red_bright;
  }

  &__promo-error {
    color: $hexcolor_red_bright;
  }

  &__promo-wrapper {
    position: relative;
  }

  &__promo-clear {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 30px;
    width: 30px;

    border: 1px solid $hexcolor_beige_ombre;
    background-color: white;
    color: $hexcolor_beige_ombre;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    border-radius: 1rem;
  }

  .promo__submit {
    &:focus {
      background-color: $hexcolor_turquoise;
    }

    position: relative;
    overflow: hidden;

    .loader {
      display: flex;
      justify-content: center;
    }

    .loader__background {
      opacity: 1;
      background-color: $hexcolor_turquoise !important;
    }

    .loader__wrap {
      position: relative;
      display: block;
    }

    .loader__clock,
    .loader__clock-face,
    .loader__clock-hands {
      width: 2rem;
      height: 2rem;
      left: 0;
    }

    .loader__clock-face {
      path {
        fill: #fff !important;
      }
    }

    .loader__clock-hands {
      path {
        fill: $hexcolor_turquoise !important;
      }
    }
  }
}

@import "./components/ProductCard.scss";
@import "./components/MobileProducts.scss";
@import "./components/FreeAccount.scss";
@import "./components/Products.scss";
